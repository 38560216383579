import CastSession from 'cast-session';
import Logger from 'lib/logger';

import AdData from 'lib/ads/ad-data';
import CreativeWorkModel from 'model/creative-work';
import ChannelModel from 'model/channel';
import ListingModel from 'model/listing';
import WatchableModel from 'model/watchable';
import ResumePoints from 'resume-points';

import castMessenger from 'cast-messenger';
import player from 'player';
import heartbeat from 'heartbeat';
import i18n from 'i18n';
import controllerEvents from 'constants/controller-events';
import featureTypes from 'constants/xvp-ads-types';
import errorCategories from 'constants/error-categories';
import messageTypes from 'constants/message-types';
import playerEvents from 'constants/player-events';
import promptTypes from 'constants/prompt-types';
import featuresTypes from 'constants/xvp-ads-types';
import heartbeatEvents from 'constants/heartbeat-events';
import splunkLogger from 'lib/telemetry/splunk-logger';
import parentalControls from 'parental-controls';
import { lookupError, getTrimmedTimeline } from 'lib/helpers';
import { DebugSender } from 'lib/debug/sender';
import { senderDebugger } from 'lib/debug/sender-receiver-debug';
import Session from 'lib/session';
import siftTracker from 'sift';
import XVP from 'lib/xvp';
import { allDeviceChecks, getDeviceDetails, getHdcpVersion, getCodecResults } from './lib/helpers/device-detection';
import ppjsLogger from './lib/debug/ppjs-logger';

const logger = new Logger('APP', { background: 'deepskyblue' });

const defaultIdleTime = 300e3; // 5 minutes
const pausedIdleTime = 1200e3; // 20 minutes
const metadataShowTime = 5e3; // 5 seconds
const defaultLinearIdleTime = 4 * 3600e3; // 4 hours

// Map player state to cast-player-overlay state
const overlayStateMapping = {
  idle: 'idle',
  initializing: 'buffering',
  initialized: 'buffering',
  preparing: 'buffering',
  prepared: 'buffering',
  ready: 'idle',
  playing: 'idle',
  paused: 'paused',
  complete: 'idle',
  released: 'idle',
  buffering: 'buffering',
  error: 'idle'
};
// Min HDCP Version
const hdcpMinVersion = 1.4;

const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};

class App {
  adData = new AdData();
  controllingSession = null;
  sessionDetails = {};

  linearIdleTime = defaultLinearIdleTime;
  debugSender = undefined;
  startingPlayback = false;
  delayProgressUpdate = false;
  lastLoadTimestamp = 0;
  easIdle = Promise.resolve();
  easIdleResolve = Function.prototype;
  playState = 'idle';

  mediaOpenedEvent = undefined;
  simulateTimer = 0;
  initialStreamCheckCompleted = false;
  streamCheckRetry = 0;
  controllerListeners = {
    [controllerEvents.load]: ({ detail }) => this.playVideo(detail),
    [controllerEvents.play]: ({ detail }) => {
      senderDebugger.sendDebugMessage('ControllerEvents PLAY CALLED', detail);

      // Ignore play messages during EAS alert
      if (this.playerController.eas) {
        return;
      }

      if (this.playerController.prompt.promptType === promptTypes.stillWatching.promptType) {
        this.showPrompt({ promptType: '' }); // Disable the prompt
        this.autoPlayCount = 0;
      }

      if (this.playerController.prompt.promptType !== promptTypes.parentalControls.promptType) {
        player.resumePlayback();
      }

      const pausedMessage = this.underOverlayMessage.querySelector('[data-is-paused]');
      if (pausedMessage) {
        this.underOverlayMessage.removeChild(pausedMessage);
      }
    },
    [controllerEvents.pause]: () => {
      this.wasPaused = true;
      if (!this.playerController.prompt.active) {
        this.enableIdleTimeout(pausedIdleTime);
      }
      this.disableLinearTimeout();

      if (this.listing && !this.playerController.prompt.active) {
        const pausedMessage = document.createElement('cast-message');
        pausedMessage.header = 'Live playback has been stopped';
        pausedMessage.message = 'Select Play on your device to resume Live TV.';
        pausedMessage.setAttribute('data-is-paused', true);
        this.underOverlayMessage.appendChild(pausedMessage);
      }

      this.overlay.showMetadataFor(0); // Don't auto-hide overlay
    },
    [controllerEvents.seek]: () => this.delayProgressUpdate = false,
    [controllerEvents.unload]: ({ detail }) => {
      senderDebugger.sendDebugMessage('ControllerEvents UNLOAD HERE CALLED', detail);

      logger.log('Shutting down playback after PlayerController unload');
      this.startingPlayback = false;
      this.wasPaused = false;
      this.idle.state = 'ready';
      if (detail.reason !== 'ERROR') {
        siftTracker.tagEvent('screen-viewed', { screen: 'Ready to Cast' });
      }
      this.overlay.showMetadata = false;
      this.overlay.showUpNext = false;
      this.mediaOpenedEvent = undefined;
      this.enableIdleTimeout(defaultIdleTime);
      this.disableLinearTimeout();
      this.clearLinearBoundaryUpdate();
      if (this.playerController.prompt.active) {
        this.showPrompt({ promptType: '' }); // Disable the prompt
      }
      this.playerController = null;
      detail.controller.removeEventListenerCollection(this.controllerListeners);
    }
  };

  senderListeners = {
    [messageTypes.getTimelineData]: ({ detail }) => detail.reply(messageTypes.timelineData, {
      timelineAdBreaks: this.adData && getTrimmedTimeline(this.adData.timelineAdBreaks) }),
    [messageTypes.controllerCreated]: ({ detail }) => {
      this.playerController = detail.controller;
      this.playerController.addEventListenerCollection(this.controllerListeners);
    },
    [messageTypes.requestAuth]: async ({ detail }) => {
      const sessionId = detail.sessionId || detail.senderId;
      senderDebugger.sendDebugMessage(`Request Auth Detail ${JSON.stringify(detail, getCircularReplacer(), 4)}`);

      try {
        const session = this.sessionDetails[sessionId] || new CastSession({ ...detail, sessionId });
        this.sessionDetails[sessionId] = session;

        detail.reply(messageTypes.authResponse, {
          status: 'IN_PROGRESS',
          sessionId
        });

        await session.auth();

        detail.reply(messageTypes.authResponse, {
          status: 'SUCCESS',
          sessionId
        });
      } catch (error) {
        detail.reply(messageTypes.authResponse, {
          status: 'FAILED',
          sessionId,
          error: {
            code: error.code,
            subCode: error.subCode
          }
        });
        this.error({
          category: errorCategories.provision,
          error
        });
      }
      if (this.sundog) {
        this.sundog.saveUUID(`sessionId:${sessionId}`);
      }
    },
    [messageTypes.promptResponse]: ({ detail }) => {
      if (!this.playerController.prompt.active) {
        return;
      }
      senderDebugger.debugPromptMessage('Prompt Response Received', {
        promptDetail: detail,
        promptType: this.playerController.prompt.promptType,
        playerControllerPrompt: this.playerController.prompt
      }
      );
      switch (this.playerController.prompt.promptType) {
        case promptTypes.stillWatching.promptType:
          this.showPrompt({ promptType: '' }); // Disable the prompt
          this.playerController.play();
          break;
        case promptTypes.parentalControls.promptType:
          if (detail.data && detail.data.pin === this.controllingSession.parentalControls.pin) {
            senderDebugger.debugPromptMessage('Prompt Parental Controls - Pin Submitted Passed - Prompt is Disabled', {
              playRequestPostPC: this.playRequestPostPC
            });

            this.showPrompt({ promptType: '' }); // Disable the prompt
            if (this.playRequestPostPC) {
              castMessenger.playerManager.load(this.playRequestPostPC);
              this.playRequestPostPC = null; // Prevent the request from being sent again
            } else {
              this.playerController.play();
            }
          } else {
            senderDebugger.debugPromptMessage('Prompt Parental Controls - Pin Submitted FAILED - Prompt is Disabled');
            detail.reply(messageTypes.pinFailed, {});
          }
          break;
        case promptTypes.upNext.promptType:
          if (detail.data && detail.data.action) {
            senderDebugger.debugPromptMessage('Prompt Parental Controls - Pin Submitted Passed - Prompt is Disabled', {
              promptUpNextDetail: detail.data
            });
            if (detail.data.action === 'play') {
              this.doPlayNext(false); // Play next episode immediately (not unattended)
            } else if (detail.data.action === 'cancel') {
              this.playNextRequest = null; // Clearing play next request prevents playing next episode
              this.showPrompt({ promptType: '' }); // Disable the prompt
              if (player.getPlayerState() === 'complete') { // if the api is active after end of a playback
                player.stop();
              }
            }
          }
          break;
      }
    },
    [messageTypes.senderConnected]: async ({ detail }) => {
      splunkLogger.logConnectionStatus('CONNECTED', detail);
      const deviceCheck = await allDeviceChecks();

      senderDebugger.sendDebugMessage('[SenderListener] CONNECTED',
        {
          DeviceDetail: JSON.stringify(Object.assign({}, deviceCheck), undefined, 4)
        });
    },
    [messageTypes.senderDisconnected]: ({ detail }) => {
      splunkLogger.logConnectionStatus('DISCONNECTED', detail);
    },
    [messageTypes.setDebug]: async ({ detail }) => {
      senderDebugger.sendDebugMessage('SET DEBUG ATTEMPT ', detail);

      if (typeof detail.idleTime === 'number') {
        this.linearIdleTime = detail.idleTime || defaultLinearIdleTime;
        this.resetLinearTimeout();
      }
      if (detail.showSafeAreas !== undefined) {
        const existingOverlay = document.querySelector('#safe-area');

        if (detail.showSafeAreas) {
          if (!existingOverlay) {
            const overlay = document.createElement('img');
            overlay.setAttribute('id', 'safe-area');
            overlay.setAttribute('src', '/safe-area-overlay.svg');
            document.body.appendChild(overlay);
          }
        } else {
          if (existingOverlay) {
            document.body.removeChild(existingOverlay);
          }
        }
      }
      // if client accidently sends strings 'false' would truthy
      if ( detail.senderDebug && detail.senderDebug.enabled && detail.senderDebug.enabled !== 'false') {
        if (!this.debugSender) {
          this.debugSender = new DebugSender(detail.senderDebug.interval).playerReady(player);
        }
        if (this.debugSender) {
          this.debugSender.startTimer();
        }
      } else if (this.debugSender) {
        this.debugSender.stopTimer();
      }

      if (detail.enabled) {
        if (!this.debug.playerPlatformApi) {
          await import(/* webpackChunkName: 'cast-debug-panel' */ 'components/cast-debug-panel');
          this.debug.playerReady(player);
        }
        this.debug.removeAttribute('hidden');
      } else {
        this.debug.setAttribute('hidden', true);
      }

      if (detail.language) {
        i18n.changeLanguage(detail.language);
      }
      if (detail.requestChannelMap || detail.requestChannelMap === 'true') {
        const channels = await ChannelModel.getChannelMap();

        const entitledChannels = channels.filter((channelObj) =>{
          const channel = channelObj.getProps ? channelObj.getProps(): channelObj;
          return channel.entitled;
        }
        ).length;
        const xboAccountId = Session.xboAccountId;
        const lastKnownEntitledCount = localStorage.getItem(`tv-entitled-channels-${xboAccountId}`);

        const channelsResult = {
          entitled: entitledChannels,
          diff: lastKnownEntitledCount ? entitledChannels - lastKnownEntitledCount : 0,
          total: channels.length,
          unentitled: channels.length - entitledChannels
        };

        senderDebugger.sendMessage('[Requested][requestChannelMap] Returned Channel Map: ', {
          channelsCount: channels.length,
          entitledChannels: entitledChannels,
          channelResult: channelsResult,
          lastKnownEntitledCount: lastKnownEntitledCount
        });
      }
      if (String(detail.getDeviceDetails) === 'true') {
        const deviceDetails = await getDeviceDetails();
        senderDebugger.sendMessage( deviceDetails.message, {
          debugData: deviceDetails.detail
        });
      }
      if (String(detail.getPPJSDetails) === 'true') {
        senderDebugger.sendMessage('[PLAYERPLATFORM]: ', {
          message: '[ppjsDetails]',
          debugData: ppjsLogger.getPPJSDetails()
        });
      }
      if (String(detail.setSendLogsToSender) === 'true') {
        senderDebugger.setSendLogsToSender(detail.sendLogs);
        senderDebugger.sendMessage('[CACTOOL][sendLogsToSender]: ', {
          message: 'Receiver Debug Logs Currently Logging to Sender:',
          debugData: {
            streamAppLogLevel: senderDebugger.getLogLevel(),
            ppjsLogLevel: ppjsLogger.getLogLevel()
          }
        });
      }
      if (String(detail.setLogLevel) === 'true') {
        senderDebugger.setLogLevel(detail.logLevel);
        const streamAppLevel = senderDebugger.getLogLevel();

        senderDebugger.sendMessage('[CACTOOL][StreamAppLogLevel]: ', {
          message: 'Stream App Log level',
          debugData: {
            streamAppLogLevel: streamAppLevel
          }
        } );
      }
      if (String(detail.getLogLevel) === 'true') {
        const cacToolLocalStorage = JSON.parse(localStorage.getItem('tv-streamCast-cac-tool')) || {};
        const streamAppLevel = senderDebugger.getLogLevel();

        senderDebugger.sendMessage('[CACTOOL][getLogLevel]: ', {
          debugData: {
            streamAppLogLevel: streamAppLevel,
            ppjsLogLevel: ppjsLogger.getLogLevel(),
            ppjsConsoleLogging: cacToolLocalStorage.ppjsConsoleLogging || false,
            settingsSticky: cacToolLocalStorage.settingsSticky || false,
            sendLogsToSender: senderDebugger.getSendLogsToSender()
          }
        });
      }
      if (String(detail.setForcedLogs) === 'true') {
        senderDebugger.setForcedLoggers(detail.forcedLoggers);
        senderDebugger.sendMessage('[CACTOOL][forcedLoggers][LogLevel]: ', {
          message: 'Array of forced loggers set:',
          debugData: {
            forcedLogLevels: senderDebugger.getForcedLoggers()
          }
        } );
      }
      if (String(detail.getForcedLogs) === 'true') {
        senderDebugger.sendMessage('[CACTOOL][forcedLoggers]: ', {
          message: '[LogLevel]',
          debugData: {
            forcedLogLevels: senderDebugger.getForcedLoggers()
          }
        } );
      }
      if (String(detail.setPPJSLogLevel) === 'true') {
        ppjsLogger.setPPJSLogLevel(detail.logLevel);
        senderDebugger.sendMessage('[PLAYERPLATFORM][LogLevel]: ', {
          message: '[PLAYERPLATFORM][LogLevel]',
          debugData: {
            logLevel: ppjsLogger.getLogLevel()
          }
        } );
      }
      if (detail.getPPJSDiagnosis === true || detail.getPPJSDiagnosis === 'true') {
        senderDebugger.sendMessage('[PLAYERPLATFORM][ppjsDiagnosis]: ', {
          debugData: ppjsLogger.getPPJSDiagnosis()
        } );
      }

      if (detail.togglePPJSVideoMonitor === true || detail.togglePPJSVideoMonitor === 'true') {
        ppjsLogger.togglePPJSVideoMonitor();
      }

      if (String(detail.togglePPJSLogging) === 'true') {
        const cacToolLocalStorage = JSON.parse(localStorage.getItem('tv-streamCast-cac-tool')) || {};
        const currPPLogging = cacToolLocalStorage.ppjsConsoleLogging || false;

        if (!currPPLogging && player && player.playerPlatform) {
          ppjsLogger.addPPJSLogging();
        } else {
          ppjsLogger.removePPJSLogging();
        }
        cacToolLocalStorage.ppjsConsoleLogging = !currPPLogging;

        localStorage.setItem('tv-streamCast-cac-tool', JSON.stringify(cacToolLocalStorage));

        senderDebugger.sendMessage('[CACTOOL][togglePPJSLogging]: ', {
          message: `[CACTOOL][recLocalStorage]: PPJS Console Logging Now Set to: ${cacToolLocalStorage.ppjsConsoleLogging} 
          currPPLogging was set to: ${currPPLogging}`,
          detail: JSON.stringify(cacToolLocalStorage, getCircularReplacer(), 4),
          ppjsConsoleLogging: cacToolLocalStorage.ppjsConsoleLogging
        });
      }
      if (detail.toggleSettingsSticky) {
        const cacToolLocalStorage = JSON.parse(localStorage.getItem('tv-streamCast-cac-tool')) || {};
        const currSticky = detail.toggleTo || cacToolLocalStorage.settingsSticky;
        cacToolLocalStorage.settingsSticky = !currSticky;

        localStorage.setItem('tv-streamCast-cac-tool', JSON.stringify(cacToolLocalStorage));

        senderDebugger.sendMessage('[CACTOOL][toggleSettingsSticky]: ', {
          message: `[CACTOOL]: PPJS Console Sticky Now Set to: ${cacToolLocalStorage.settingsSticky}`,
          detail: JSON.stringify(cacToolLocalStorage, getCircularReplacer(), 4)
        });
      }

      if (detail.getCodecResults === true || detail.getCodecResults === 'true') {
        const codecTestResults = getCodecResults();
        detail.reply( '[deviceDetails][vp9CodecTests]: ', codecTestResults );
      }
      if (detail.requestTokenSummary === true || detail.requestTokenSummary === 'true') {
        senderDebugger.debugNetworkMessage('[Requested] Returned TokenSummary', {
          deviceTokenSummary: this.controllingSession.session.tokenSummary,
          deviceSatToken: this.controllingSession.session.serviceAccessToken,
          deviceXSCT: this.controllingSession.xsct,
          sessionId: detail.sessionId
        });
      }
    },
    [messageTypes.setParentalControls]: ({ detail }) => {
      const {
        enabled,
        pin,
        channelLocks,
        ratingLocks,
        networkLocks,
        titleLocks
      } = detail;

      const session = this.sessionDetails[detail.sessionId];

      if (!session) {
        detail.reply(messageTypes.parentalControlsUpdateResponse, { status: 'FAILED', reason: 'No session found' });
        return;
      }

      session.updateParentalControls({
        enabled,
        pin,
        channelLocks,
        ratingLocks,
        networkLocks,
        titleLocks
      });

      siftTracker.updateCustomObject({ parental_controls: detail.enabled ? 'Enabled' : 'Disabled' });

      detail.reply(messageTypes.parentalControlsUpdateResponse, { status: 'SUCCESS' });
    },
    [messageTypes.setSenderConfig]: ({ detail }) => {
      const config = Object.fromEntries(Object.entries(detail)
        .filter(([key, value]) => !['sessionId', 'senderId', 'reply'].includes(key)));
      const session = this.sessionDetails[detail.sessionId];

      if (!session) {
        detail.reply(messageTypes.senderConfigUpdateResponse, { status: 'FAILED', reason: 'No session found' });
        return;
      }

      this.upNext.autoplayEnabled = typeof detail.autoPlayEnabled !== 'undefined' ?
        detail.autoPlayEnabled : this.controllingSession.autoPlayEnabled;

      session.updateSenderConfig(config);

      detail.reply(messageTypes.senderConfigUpdateResponse, { status: 'SUCCESS' });
    },
    [messageTypes.simulateEas]: ({ detail }) => {
      if (detail.eas) {
        this.playerListeners[playerEvents.emergencyAlertStarted]();
        player.easActive = true;
      } else {
        this.playerListeners[playerEvents.emergencyAlertComplete]();
        player.easActive = false;
      }
    },
    [messageTypes.simulateError]: async ({ detail }) => {
      const { category, code, subCode, description, ppEventType } = detail;
      senderDebugger.sendMessage('[SenderListener] SIMULATE ERROR:',
        { debugData:
        Object.assign({
          message: 'SIMULATE ERROR Details: ',
          detailData: Object.assign({}, detail,
            { 'player.handleMediaError:': !!player.errorHandler })
        })
        });

      if (code === '00236' || code === '236') {
        const hdcpVersion = await getHdcpVersion();
        this.error(
          {
            category: errorCategories.outputRestricted,
            fatal: false,
            module: 'Video',
            msg: `HDCP version check FAILED! HDCP: ${hdcpVersion}`,
            lookup: {
              category: errorCategories.outputRestricted,
              tvapp: '00236'
            },
            description: `HDCP version check FAILED! HDCP: ${hdcpVersion}`
          }
        );
        return;
      }
      if (code === -9002 ) {
        this.error({
          category: errorCategories.unsupportedDevice,
          error: detail
        });
        return;
      }
      if (category.includes('player') && player.errorHandler) {
        player.errorHandler.handleMediaError(ppEventType, { category, code, subCode, description });
      } else {
        this.error({ category, code, subCode, description });
      }
    },
    [messageTypes.simulatePrompt]: ({ detail }) => {
      senderDebugger.debugPromptMessage('Simulate Prompt Controls', {
        promptUpNextDetail: detail
      });
      if (detail.active) {
        const promptOptions = Object.values(promptTypes).find(({ promptType }) => detail.promptType === promptType);
        if (promptOptions) {
          this.showPrompt(promptOptions);
        }
      } else {
        this.showPrompt({ promptType: '' });
      }
    },
    [messageTypes.triggerLinearBoundary]: () => {
      this.doLinearBoundaryUpdate(this.listing);
    },
    [messageTypes.loadTokenSummary]: ({ detail }) => {
      const session = this.sessionDetails[detail.sessionId];
      console.log('session controllingSession test : ' + JSON.stringify(this.controllingSession) );
      console.log('session session test : ' + JSON.stringify(session) );

      detail.reply( '[deviceDetails][tokensummary]: requested tokenSummary: ', {
        deviceTokenSummary: this.controllingSession.session.tokenSummary,
        deviceSatToken: this.controllingSession.session.serviceAccessToken,
        deviceXSCT: this.controllingSession.xsct,
        sessionId: detail.sessionId
      } );
    }
  };

  playerListeners = {
    [playerEvents.adBreakComplete]: ({ detail }) => {
      logger.logBlock('AD BREAK COMPLETE FROM PLAYER', (logger) => {
        logger.log('AD BREAK COMPLETE ', detail);
      });
      this.delayProgressUpdate = false;
      this.scrubber.playerEvents[detail.type]();
      if (this.adData && this.adData.mediaOpenedFiredDuringAdBreak) {
        this.playerListeners[playerEvents.mediaOpened]({ detail: this.mediaOpenedEvent } );
      }
    },
    [playerEvents.adBreakStart]: ({ detail }) => {
      logger.logBlock('AD BREAK START FROM PLAYER', (logger) => {
        logger.log('AD BREAK START ', detail);
      });
      this.adData.startAdMonitor(player);
      this.disableIdleTimeout();
      this.scrubber.playerEvents[detail.type]();
      this.playerController.isFFRestricted = player.isFFRestricted();
      this.playerController.isRWRestricted = this.watchable.isRWRestricted();
      this.playerController.isPauseRestricted = this.watchable.isPauseRestricted();
    },
    [playerEvents.adHealing]: (detail) => {
      logger.logBlock('AD HEALING IN PROGRESS', (logger) => {
        logger.log('AD HEALING  for player in state: ', detail);
      });

      if (detail === 'buffering') {
        player.api.setPositionRelative(0);
      }
      this.scrubber.updateAdData(this.adData);
      player._onDurationChanged();
    },
    [playerEvents.adProgress]: ({ detail }) => {
      if (!this.adData.adsPlaying ) {
        return;
      }

      logger.logBlock('AD PROGRESS FROM PLAYER', (logger) => {
        logger.log('AD PROGRESS ', detail);
      });

      this.scrubber.position = detail.currentAdBreak.position;
    },
    [playerEvents.adStart]: ( { detail } ) => {
      logger.logBlock('AD START FROM PLAYER', (logger) => {
        logger.log('AD START ', detail);
      });
      this.scrubber.updateAdData(this.adData);

      this.disableIdleTimeout();
    },
    [playerEvents.adComplete]: ( { detail } ) => {
      logger.logBlock('AD COMPLETE FROM PLAYER', (logger) => {
        logger.log('AD COMPLETE ', detail);
      });
    },
    [playerEvents.bufferStart]: ({ detail }) => {
      senderDebugger.sendDebugMessage('[APP][playerEvents] - bufferStart', {
        detailBuffer: detail
      });
      logger.logBlock('bufferStart FROM PLAYER', (logger) => {
        logger.log('detail:', detail);
      });
    },
    [playerEvents.bufferComplete]: ({ detail }) => {
      senderDebugger.sendDebugMessage('[APP][playerEvents] - bufferComplete', {
        detailBuffer: detail
      });
      logger.logBlock('bufferComplete FROM PLAYER', (logger) => {
        logger.log('detail:', detail);
      });
      this.overlay.state = 'idle';
    },
    [playerEvents.playerReady]: async ({ detail }) => {
      logger.logBlock('playerReady FROM PLAYER', (logger) => {
        logger.log('detail:', detail);
      });
    },
    [playerEvents.mediaOpened]: ({ detail }) => {
      logger.logBlock('mediaOpened FROM PLAYER', (logger) => {
        logger.log('detail:', detail);
      });

      if (this.mediaOpenedEvent && detail.mediaOpenedDelayed && detail.type === playerEvents.mediaOpenedDelayed) {
        detail = Object.assign({}, detail, this.mediaOpenedEvent);
        this.delayProgressUpdate = false;
        this.overlay.showMetadataFor(metadataShowTime);
        this.scrubber.onAdBreakChanged();
      }
      this.mediaOpenedEvent = detail;
      if (this.adData.adsPlaying) {
        this.adData.mediaOpenedFiredDuringAdBreak = true;
        return;
      }
      this.adData.mediaOpenedFiredDuringAdBreak = false;
      delete detail.type;
      delete detail.adData;
      castMessenger.broadcastMessage(messageTypes.mediaOpened, detail);
      senderDebugger.sendDebugMessage('[APP][MediaOpened] ', {
        mediaOpenedDetail: detail });
      this.playerController.isFFRestricted = player.isFFRestricted();
      this.scrubber.isLinear = this.watchable.isLinear() || (this.watchable.isOTTInProgress() && !this.watchable.isRestartable());
      this.scrubber.mediaLoading = false;
      this.scrubber.isRecordingInProgress = false;
      this.scrubber.updateAdData(this.adData);
      if (this.watchable.isOTT() && !this.watchable.isRestartable()) {
        this.scrubber.min = this.watchable.startTime;
        this.scrubber.max = this.watchable.endTime;
      }
      if (this.watchable.isRecordingInProgress()) {
        this.scrubber.isRecordingInProgress = true;
      }
      this.clearErrorScreen();
      this.disableIdleTimeout();
      // Enable linear timeout only for linear playback
      if (this.listing) {
        this.enableLinearTimeout();
      }

      if (detail.duration) {
        this.scrubber.max = detail.duration;
      }

      if (this.autoPlayCount >= 3) {
        senderDebugger.debugPromptMessage('Show Prompt Still Watching', {
          promptUpNextDetail: detail.data
        });
        this.showPrompt(promptTypes.stillWatching);
      }
    },
    [playerEvents.mediaFailed]: async ({ detail }) => {
      logger.logBlock('mediaFailed FROM PLAYER', (logger) => {
        logger.log('detail:', detail);
      });

      senderDebugger.sendDebugMessage('[APP][mediaFailed] FROM PLAYER ', {
        mediaFailedDetail: JSON.stringify(detail, getCircularReplacer(), 4) });

      const geoFenceErrorMessage = detail.msg && detail.msg.includes('geolocation');
      if (this.debugSender) {
        this.debugSender.stopTimer();
      }

      if (detail.isMidStream) {
        splunkLogger.onVideoEnd();
      }

      if (String(detail.subCode) === '12007') {
        // // TODO: handle tve variant pivot
        const tveVariantUrl = (this.channel || {}).tveVariant;
        if (tveVariantUrl) {
          const errorDetails = lookupError(['player', detail.code, detail.subCode]);
          splunkLogger.onError({
            ...detail,
            lookup: errorDetails
          });
          this.requestChannelWithUrl(tveVariantUrl);
          return;
        }
      }

      if (detail.code === -9002 || detail.code === 403 && [14007].includes(detail.subCode)) {
        const hdcpVersion = await getHdcpVersion();
        // License failed from consec and hdcp version is less than 1.4

        senderDebugger.debugErrorMessage(`[APP][MEDIA][HDCP] LICENSE FAILED 
        hdcp version:${hdcpVersion} }`, {
          hdcpVersion: hdcpVersion,
          hdcpMinVersion: hdcpMinVersion
        });
        if (hdcpVersion < hdcpMinVersion) {
          splunkLogger.onError({
            category: errorCategories.outputRestricted,
            code: 'hdcpVersionFailed',
            subCode: 2,
            fatal: false,
            module: 'Video',
            msg: `HDCP version check FAILED! HDCP: ${hdcpVersion}`,
            lookup: {
              category: errorCategories.outputRestricted,
              tvapp: '00236'
            }
          });
        }
        this.error({
          category: errorCategories.unsupportedDevice,
          error: detail
        });
      } else if (geoFenceErrorMessage) {
        this.error({
          category: errorCategories.geofenceError,
          error: {
            ...detail,
            fatal: false
          }
        });
      } else if (detail.offlineDetected) {
        this.error({
          category: errorCategories.offline,
          error: detail
        });
      } else {
        this.error({
          category: [
            errorCategories.player,
            player.watchable ? player.watchable.getErrorCategory() : undefined
          ],
          error: detail
        });
      }
    },
    [playerEvents.mediaRetry]: ({ detail }) => {
      logger.logBlock('mediaretry FROM PLAYER', (logger) => {
        logger.log('detail:', detail);
      });
      let errorDetails = lookupError(['player', player.watchable.getErrorCategory(), detail.code, detail.subCode]);
      const geoFenceErrorMessage = detail.msg && detail.msg.includes('geolocation');

      if (geoFenceErrorMessage) {
        this.error({
          category: errorCategories.geofenceError,
          error: {
            ...detail,
            fatal: false
          }
        });
        return;
      }

      if ((detail.code === -9002 || detail.code === 403 ) && [14007].includes(detail.subCode)) {
        errorDetails = lookupError([errorCategories.unsupportedDevice, detail.code, detail.subCode]);
      }

      splunkLogger.onError({
        ...detail,
        lookup: errorDetails
      });
    },
    [playerEvents.mediaProgress]: ({ detail }) => {
      if (this.adData && this.adData.adsPlaying) {
        return;
      }
      this.adData.stopAdMonitor(player);
      // Add an intentional delay to updating scrubber position so user can see
      // the scrubber update for SET_POS and SET_POS_REL messages. See the "seeking"
      // state in the playStateChanged event handler for the actual update.
      if (!this.delayProgressUpdate) {
        this.scrubber.position = this.watchable.isOTTInProgress() ? new Date().getTime() : Math.round(detail.position);
      }
      if ((!this.watchable.isOTT() || this.watchable.isRestartable())
        && (this.scrubber.min === undefined || detail.startposition !== this.scrubber.min || this.scrubber.max !== detail.endposition)) {
        this.scrubber.min = detail.startposition;
        this.scrubber.max = detail.endposition;
      }

      const timeRemaining = Math.round((detail.endposition - detail.position) / 1000);
      this.upNext.timeRemaining = timeRemaining;

      const upNextPromptShowing = this.playerController.prompt.promptType === promptTypes.upNext.promptType;
      const willAutoPlay = this.controllingSession.autoPlayEnabled && this.playNextRequest;
      /**
       * Ad to asset transition takes some time.
       * Make sure it's the asset playback to display next episode prompt
      */
      const adMidRollsExist = Array.isArray(this.adData.timelineAdBreaks) && this.adData.timelineAdBreaks.length > 1;
      const adToAssetTransition = adMidRollsExist && detail.endposition < Number(this.watchable.duration);

      if (upNextPromptShowing) {
        // Clear up-next prompt if there is no playNextRequest, if auto play is
        // disabled, or if the user has seeked away from the countdown range
        if (!willAutoPlay || timeRemaining > 20) {
          logger.log('Hiding up-next prompt');
          senderDebugger.sendDebugMessage('[APP][mediaProgress] - Hiding up-next prompt', {
            timeRemaining: timeRemaining,
            willAutoPlay: willAutoPlay
          });
          this.showPrompt({ promptType: '' });
        }
      } else {
        // Show up-next prompt when:
        //  - 20 seconds from end of episode
        //  - there is a playNextRequest
        //  - auto play is enabled
        if (willAutoPlay && timeRemaining <= 20 && !adToAssetTransition) {
          senderDebugger.debugPromptMessage('Show up-next prompt - mediaProgress', {
            playNextRequestData: this.playNextRequest,
            willAutoPlay: willAutoPlay
          });
          logger.log('Showing up-next prompt');
          senderDebugger.sendDebugMessage('[APP][mediaProgress] - showing up-next prompt', {
            timeRemaining: timeRemaining,
            willAutoPlay: willAutoPlay
          });
          this.showPrompt({
            ...promptTypes.upNext,
            details: {
              watchableUrl: this.playNextRequest.media.contentId
            }
          });
        }
      }
    },
    [playerEvents.mediaEnded]: ({ detail }) => {
      const autoplayDisabled = !this.controllingSession.autoPlayEnabled && this.playNextRequest;

      heartbeat.finishWatching();
      splunkLogger.onVideoEnd();

      // Do an unattended play next or stop the player and show up next card
      if (autoplayDisabled) {
        senderDebugger.debugPromptMessage('Show up-next prompt - media ended', {
          playNextRequestData: this.playNextRequest,
          controllingSessionData: this.controllingSession
        });
        logger.log('Showing up-next prompt');

        this.showPrompt({
          ...promptTypes.upNext,
          details: {
            watchableUrl: this.playNextRequest.media.contentId
          }
        });
      } else {
        this.doPlayNext(true).then((result) => result || player.stop());
      }
    },
    [playerEvents.durationChanged]: ({ detail }) => {
      logger.logBlock('durationChanged FROM PLAYER', (logger) => {
        logger.log('detail:', detail);
      });
    },
    [playerEvents.bitrateChange]: ({ detail }) => {
      senderDebugger.sendDebugMessage('[APP][BitrateChange] FROM PLAYER ', {
        bitrateChangeDetail: detail });
      logger.logBlock('bitrateChange FROM PLAYER', (logger) => {
        logger.log('detail:', detail);
      });
    },
    [playerEvents.playbackStarted]: ({ detail }) => {
      logger.logBlock('playbackStarted FROM PLAYER', (logger) => {
        logger.log('detail:', detail);
      });
      castMessenger.broadcastMessage(messageTypes.debugSender,
        { debugData: { message: 'playbackStarted', duration: player.getPlayerDuration(), detail: detail,
          playerStatus: player.getPlayerState() } });
      if (this.scrubber.max !== player.getPlayerDuration()) {
        this.scrubber.max = player.getPlayerDuration();
      }
    },
    [playerEvents.playStateChanged]: ({ detail }) => {
      if (detail.type === 'PlaybackStarted') {
        // Fake a "playing" state change for playback started. This is to work
        // around a PP issue where it isn't sending an initial "playing" state
        // for some media types
        logger.log('Converting PlaybackStarted event to fake PlayStateChanged "playing" event');
        detail = {
          ...detail,
          type: 'PlayStateChanged',
          playState: 'playing'
        };
        this.clearErrorScreen();
        // if this is the first event due to some failure with DAI or VEX update scrubber
        if (this.scrubber.adData !== this.adData) {
          this.scrubber.updateAdData(this.adData);
        }
      }

      if (!detail.playState) {
        return; // Ignore events without a playState
      }
      logger.logBlock(`playStateChanged FROM PLAYER. State:  ${ this.playState } to ${ detail.playState}`, (logger) => {
        logger.log('detail:', detail);
      });
      const previousState = this.playState;

      this.playState = detail.playState;

      this.overlay.state = overlayStateMapping[detail.playState] || this.overlay.state;
      if (!detail.adsPlaying) {
        this.overlay.showScrubber = detail.playState === 'buffering';
      }
      senderDebugger.sendDebugMessage('[APP][playStateChange] FROM PLAYER ', {
        playStateChangedDetail: detail,
        previousPlayState: previousState });

      switch (detail.playState) {
        case 'complete':
          heartbeat.finishWatching();
          break;
        case 'playing':
          if (this.wasPaused) {
            const overlayState = this.overlay.state;
            this.overlay.state = 'playing';
            setTimeout(() => {
              if (this.overlay.state === 'playing') {
                this.overlay.state = overlayState;
              }
            }, metadataShowTime);
          }
          this.startingPlayback = false;
          this.wasPaused = false;
          if (!this.playerController.prompt.active) {
            this.disableIdleTimeout();
          }
          if (this.listing) {
            this.enableLinearTimeout();
          }
          if (!this.adData.adsPlaying && previousState !== 'buffering' || this.overlay.showMetadata) {
            this.overlay.showMetadataFor(metadataShowTime);
          }
          break;
        case 'seeking':
          if (!this.adData.adsPlaying || !this.adData.isAdBreakNearCurrentPosition(player) ) {
            this.overlay.showMetadataFor(metadataShowTime);
            this.delayProgressUpdate = false;
            setTimeout(() => this.scrubber.position = Math.round(this.playerController.currentTimeSec * 1000), 1000);
          }
          break;
      }
    },

    [playerEvents.seekComplete]: ({ detail }) => {
      if (!this.adData.adsPlaying || !this.adData.isAdBreakNearCurrentPosition(player) ) {
        this.overlay.showMetadataFor(metadataShowTime);
      }
      this.delayProgressUpdate = false;
    },

    [playerEvents.emergencyAlertStarted]: () => {
      if (this.playerController.eas) {
        return;
      }

      this.easIdle = new Promise((resolve) => this.easIdleResolve = resolve);
      this.overlay.showMetadata = false;
      this.overlay.showScrubber = false;
      this.playState = player.getPlayerState();
      this.playerController.eas = true;
    },
    [playerEvents.emergencyAlertComplete]: () => {
      if (!this.playerController.eas) {
        return;
      }

      this.playerController.eas = false;
      this.easIdleResolve();
    },
    [playerEvents.emergencyAlertFailure]: () => {
      if (!this.playerController.eas) {
        return;
      }

      this.playerController.eas = false;
      this.easIdleResolve();
    },
    [playerEvents.streamBoundary]: ({ detail }) => {
      if (!this.watchable.isVirtualStream() || this.initialStreamCheckCompleted) {
        return;
      }

      this.runStreamCheck(detail);
    }
  };

  async runStreamCheck(boundaryData) {
    try {
      const isStreamValid = await this.watchable.streamCheck({
        sourceStreamId: boundaryData.sourceStreamId,
        signalId: boundaryData.signalId,
        zipCode: this.controllingSession.currentPostalCode
      });

      if (isStreamValid) {
        this.initialStreamCheckCompleted = true;
        return;
      }

      this.handleStreamCheckFailure({
        errorCode: 'streamCheckFailed',
        boundaryData
      });
    } catch (e) {
      this.handleStreamCheckFailure({
        errorCode: 'streamCheckServerError',
        boundaryData
      });
    }
  }

  handleStreamCheckFailure({ errorCode, boundaryData }) {
    if (this.streamCheckRetry >= 2) {
      this.error({
        category: errorCategories.player,
        error: {
          code: errorCode,
          fatal: errorCode !== 'streamCheckFailed'
        }
      });
      return;
    }

    this.streamCheckRetry++;
    this.runStreamCheck(boundaryData);
  }

  async requestChannelWithUrl(channelRawUrl) {
    const channel = await ChannelModel.loadFromSelfLink(channelRawUrl);
    this.tvePivotRequest = castMessenger.createLoadRequest({ channelId: channel.channelId });
    castMessenger.playerManager.load(this.tvePivotRequest);
    this.tvePivotRequest = null;
  }

  heartbeatListeners = {
    [heartbeatEvents.failed]: ({ detail }) => {
      senderDebugger.debugHeartbeatMessage('[APP][heartbeatEvents] Heart Beat FAILED ', {
        heartbeatFailedDetail: detail });
      this.error({
        category: errorCategories.heartbeat,
        error: XVP.getFeature(featuresTypes.xvpHeartbeats) ? {
          code: detail.response && detail.response.status,
          subCode: detail.response && detail.response.statusSubCode,
          ...detail.response
        } : (detail.error && detail.error.xhr.xtv || {})
      });
    }
  };

  defaultPrivacyPrefs = {
    targetedAdOptIn: false,
    audienceMeasurement: false,
    userPrivacyString: ''
  };

  constructor() {
    this.idle = document.querySelector('cast-idle');
    this.overlay = document.querySelector('cast-player-overlay');
    this.entityMetadata = document.querySelector('cast-entity-metadata');
    this.scrubber = document.querySelector('cast-scrubber');
    this.upNext = document.querySelector('cast-up-next');
    this.debug = document.querySelector('cast-debug-panel');
    this.message = document.querySelector('#message');
    this.underOverlayMessage = document.querySelector('#under-overlay-message');

    castMessenger.addEventListenerCollection(this.senderListeners);
    player.addEventListenerCollection(this.playerListeners);
    heartbeat.addEventListenerCollection(this.heartbeatListeners);
    senderDebugger.addDebugCastListeners(castMessenger);
    senderDebugger.addDebugCastListeners(player);
    this.adData.addEventListener(playerEvents.adHealing, (healType) => {
      this.playerListeners[playerEvents.adHealing](healType);
    });
  }
  get creativeWork() {
    return this._creativeWork;
  }

  set creativeWork(creativeWork) {
    this._creativeWork = creativeWork;
    senderDebugger.sendDebugMessage('[APP][set creative work setter] 1 START', { creativeWorkFound: creativeWork });
    if (!creativeWork) {
      logger.log('Clearing creativeWork');
      this.idle.backgroundAction = null;
      this.entityMetadata.creativeWork = null;
      this.playerController.creativeWork = null;
      return;
    }

    logger.log('Setting creativeWork:', creativeWork.title);
    creativeWork.loadSeries().then(() => {
      this.idle.backgroundAction = creativeWork.series ? creativeWork.series.image : creativeWork.image;
      this.upNext.backgroundAction = creativeWork.series ? creativeWork.series.image : creativeWork.image;
      this.entityMetadata.creativeWork = creativeWork;
      this.playerController.creativeWork = creativeWork;
    });
  }

  get watchable() {
    return this._watchable;
  }

  set watchable(watchable) {
    senderDebugger.sendDebugMessage('[APP][setter watchable] 0 Watchable setting started with:', { watchableStartObj: watchable });
    this._watchable = watchable;
    this.adData.watchable = watchable;
    senderDebugger.sendDebugMessage('[APP][setter watchable] 1 Watchable prop names: ',
      {
        watchableProps: watchable ? Object.getOwnPropertyNames(watchable) : {}
      } );
    if (!watchable) {
      this.playerController.watchableUrl = null;
      this.entityMetadata.logo = null;
      if (player.watchable) {
        player.watchable = null;
      }
      return;
    }

    if (watchable.contentProvider) {
      this.entityMetadata.logo = watchable.contentProvider.logo;
    } else if (watchable.channel) {
      this.entityMetadata.logo = watchable.channel.logo;
    }

    watchable.loadCreativeWork().then(() => {
      this.creativeWork = watchable.creativeWork;
    }).catch(async (error) => {
      senderDebugger.debugErrorMessage('[APP][setter watchable] ERROR WHILE LOADING CREATIVE WORK!!!! ',
        {
          loadCreativeWorkError: error
        } );
    });

    this.playerController.watchableUrl = watchable.selfLinkUrl;

    this.preparePlayNextRequest();
  }

  get channel() {
    return this._channel;
  }

  set channel(channel) {
    this._channel = channel;
    this.clearLinearBoundaryUpdate();

    if (!channel || (channel && !channel.logo)) {
      this.entityMetadata.logo = null;
      return;
    }
    senderDebugger.sendDebugMessage('[APP][set channel] 3 SETTER CHANNEL COMPLETE- clearLinearBoundaryUpdate() ', {
      channel: channel
    });

    this.entityMetadata.logo = channel.logo;
  }

  get listing() {
    return this._listing;
  }

  set listing(listing) {
    this._listing = listing;
    this.clearLinearBoundaryUpdate();
    if (listing) {
      listing.loadDetails().then(() => {
        this.creativeWork = listing.creativeWork;
        this.playerController.currentlyAiringProgramId = listing.creativeWork ? listing.creativeWork.programId : '';
      }).catch(async (error) => {
        // Handle a listing load failure
        senderDebugger.debugErrorMessage('[APP][playChannelId][SET LISTING LoadDetails] ERROR Listing Details!!!! ',
          {
            listingError: error
          } );
        this.error(error);
        return;
      });

      // Fetch next listing 1-5 minutes before linear boundary
      const boundaryTime = listing.endTime - Date.now();
      const jitter = 60e3 + Math.round(Math.random() * 240e3);
      const updateDelay = Math.max(0, boundaryTime - jitter);
      this.linearBoundaryTimer = setTimeout(() => this.upcomingLinearBoundary(1000), updateDelay);
    }
  }

  broadcastAdEvent(adMessageType, detail) {
    ['senderId', 'reply'].forEach((item) => delete detail[item]);
    castMessenger.broadcastMessage(adMessageType, detail);
  }

  startup() {
    // Briefly dealy going to "ready" to give sender time to send initial LOAD_VIDEO
    setTimeout(() => {
      if (!this.startingPlayback && this.idle.state !== 'playing') {
        this.idle.state = 'ready';
        siftTracker.tagEvent('screen-viewed', { screen: 'Ready to Cast' });
      }
    }, 2000);
    this.enableIdleTimeout(defaultIdleTime);
    siftTracker.init().then(() => {
      siftTracker.updateCustomObject({ cast_sdk_version: (window.cast && window.cast.framework.VERSION) || 'NA' });
    });
  }

  clearLinearBoundaryUpdate() {
    if (this.linearBoundaryTimer) {
      logger.log('Clearing linear boundary update timer');
      this.linearBoundaryTimer = clearTimeout(this.linearBoundaryTimer);
    }
  }

  async upcomingLinearBoundary(retryDelay) {
    if (!this.channel || !this.listing) {
      return;
    }
    logger.log('Preloading linear boundary data');

    try {
      const boundaryTime = Math.max(Date.now(), this.listing.endTime + 1000);
      const nextListing = await this.channel.getListingAt(boundaryTime);

      await nextListing.loadDetails();

      logger.log('Applying linear boundary update at', new Date(boundaryTime));

      this.linearBoundaryTimer = setTimeout(
        () => this.doLinearBoundaryUpdate(nextListing),
        Math.max(0, boundaryTime - Date.now()));
    } catch (error) {
      logger.error(error);
      senderDebugger.debugErrorMessage('[APP][upcomingLinearBoundary] ERROR WHILE linear boundary timer!!!! ',
        {
          boundaryrror: error
        } );
      // If anything goes wrong, try again after retryDelay
      const newDelay = Math.min(60000, Math.round(retryDelay * (1 + Math.random())));
      //  logger.log('Retry linear boundary update at', new Date(Date.now() + retryDelay));
      this.linearBoundaryTimer = setTimeout(() => this.upcomingLinearBoundary(newDelay), retryDelay);
    }
  }

  async doLinearBoundaryUpdate(nextListing) {
    senderDebugger.sendDebugMessage('Applying linear boundary update');
    await this.easIdle; // Do linear boundary update after EAS alert is complete

    player.fireSiftVPOnLinearBoundary();
    this.listing = nextListing;
    player.watchable = nextListing;

    if (await parentalControls.isLocked(this.controllingSession.parentalControls, nextListing)) {
      this.showPrompt(promptTypes.parentalControls);
      return;
    }

    this.overlay.showMetadataFor(metadataShowTime);
  }

  async preparePlayNextRequest() {
    senderDebugger.sendDebugMessage('preparePlayNextRequest - ');

    try {
      await this.watchable.loadCreativeWork();
      const nextEpisode = await this.watchable.creativeWork.getNextEpisode(this.watchable);

      if (!nextEpisode) {
        logger.log('No next episode. Giving up on PlayNext!');
        senderDebugger.sendDebugMessage('No next episode. Giving up on PlayNext!');
        return;
      }
      senderDebugger.sendDebugMessage('[APP] : preparePlayNextRequest nextEpisode FOUND ');
      nextEpisode.loadSeries().then(() => {
        this.upNext.creativeWork = nextEpisode;
        this.upNext.senderPartnerId = this.controllingSession.senderPartnerId;
        this.upNext.autoplayEnabled = this.controllingSession.autoPlayEnabled;
      });

      const watchable = await nextEpisode.getBestOptionToWatch(this.controllingSession.inHome);

      if (!watchable) {
        logger.log('No watch option for next episode. Giving up on PlayNext!');
        senderDebugger.sendDebugMessage('No watch option for next episode. Giving up on PlayNext!');
        return;
      }

      const programId = (watchable.creativeWork || {}).programId;

      this.playNextRequest = castMessenger.createLoadRequest({ watchable, programId });
      senderDebugger.sendDebugMessage('PlayNext request is ready');
      this.nextWatchable = watchable;
    } catch (error) {
      logger.error('PlayNext request prep failed:', error);
      senderDebugger.sendDebugMessage('PlayNext request prep failed:', {
        programId: (this.watchable.creativeWork || {}).programId,
        errorDetail: error
      });
    }
  }

  async doPlayNext(unattended = true) {
    if (!this.controllingSession || !this.controllingSession.autoPlayEnabled) {
      logger.log('Auto-play not enabled in sender config; Not doing auto-play');
      return false;
    }

    if (!this.playNextRequest) {
      logger.log('No PlayNext request prepared; Not doing auto-play');
      return false;
    }

    const request = this.playNextRequest;
    this.playNextRequest = null; // Prevent the request from being sent again

    request.media.customData.unattendedAutoPlay = unattended;
    request.media.customData.restart = true;

    if (await parentalControls.isLocked(this.controllingSession.parentalControls, this.nextWatchable)) {
      this.nextWatchable = null;
      this.playRequestPostPC = request;
      this.showPrompt(promptTypes.parentalControls);
      return true;
    }

    castMessenger.playerManager.load(request);
    return true;
  }

  async playProgramId({ programId, timestamp, restart, resumePoint }) {
    const creativeWork = await CreativeWorkModel.load(programId);

    if (!await creativeWork.hasWatchOptions()) {
      this.error({
        category: errorCategories.player,
        description: 'No watch option available'
      });
      return;
    }

    let watchable = await creativeWork.getBestOptionToWatch(this.controllingSession.inHome);

    if (!watchable) {
      this.error({
        category: errorCategories.contentRestricted,
        description: 'Program is not castable'
      });
      return;
    }

    if (
      watchable.isGeoFenced() ||
      (XVP.getFeature(featuresTypes.xvpHeartbeats) && watchable.channel && watchable.channel.hasGeofencedLocatorType())
    ) {
      try {
        watchable = await this.checkGeoFencedStream(watchable);
      } catch (error) {
        return;
      }
    }

    if (!watchable || this.playerController.error) {
      return;
    }

    await this.playWatchable({ watchable, programId, timestamp, restart, resumePoint });
  }

  async playWatchable({ watchable, programId, timestamp, restart, resumePoint }) {
    this.overlay.showMetadataFor(0);
    senderDebugger.sendDebugMessage('[APP][playWatchable] called: ',
      {
        watchable: JSON.stringify(watchable, getCircularReplacer(), 4),
        programId: programId,
        playerStatus: player.getPlayerState()
      });

    if (watchable.isListing()) {
      if (this.lastLoadTimestamp > timestamp) {
        // A newer LOAD_VIDEO message was received, give up
        logger.log('Giving up on playback, newer LOAD_VIDEO request was received');
        return;
      }

      this.channel = watchable.channel;
      this.listing = watchable;
      await this.listing.loadDetails();
      await this.listing.creativeWork.loadSeries();

      player.playAsset({
        channelId: this.channel.channelId,
        watchable: this.listing
      });
    } else {
      if (!resumePoint && resumePoint !== 0) {
        // No resume point provided -- calculate from `restart` or load it
        if (restart) {
          resumePoint = 0;
        } else {
          try {
            resumePoint = await ResumePoints.getResumePoint({ watchable, programId });
          } catch (error) {
            logger.error(error);
          }
        }
      } else {
        resumePoint = Number(resumePoint);
        // We'll use the provided resume point -- set `restart` based on it
        restart = (resumePoint === 0);
      }

      resumePoint = resumePoint === 0 ? 500 : resumePoint;

      if (this.lastLoadTimestamp > timestamp) {
        // A newer LOAD_VIDEO message was received, give up
        logger.log('Giving up on playback, newer LOAD_VIDEO request was received');
        return;
      }

      this.watchable = watchable;
      await this.watchable.loadCreativeWork();
      await this.watchable.creativeWork.loadSeries();
      player.restartVideo = restart;

      player.playAsset({
        programId,
        watchable: this.watchable,
        resumePoint
      });
    }
  }

  async playChannelId({ channelId, timestamp }) {
    let errorExists = undefined;
    senderDebugger.sendDebugMessage('[APP][PlayChannelID] 1 CALLED', {
      channelId: channelId,
      watchableExists: !!this.watchable
    });
    const channel = await ChannelModel.load(channelId).catch(async (error) => {
      // Handle a channel map load failure
      senderDebugger.debugErrorMessage('[APP][playChannelId][CHANNEL MAP LOAD] ERROR WHILE LOADING CHANNEL MAP!!!! ',
        {
          channelmapError: error
        } );
      errorExists = error;
      return;
    });

    if (!errorExists && !channel.channelId) {
      errorExists = XVP.errorFormatted({
        errorType: 'channelMapMissingChannelId',
        options: {
          channelId: channelId
        } }
      );
    }

    if (channel && !channel.isCastable()) {
      errorExists = {
        category: errorCategories.contentRestricted,
        description: 'Channel is not castable'
      };
    }
    if (errorExists) {
      this.error(errorExists);
      return;
    }

    if (this.lastLoadTimestamp > timestamp) {
      // A newer LOAD_VIDEO message was received, give up
      logger.log('Giving up on playback, newer LOAD_VIDEO request was received');
      return;
    }

    this.channel = channel;
    this.listing = await this.channel.getListingAt(Date.now());
    senderDebugger.sendDebugMessage('[APP][PlayChannelID] 4 LISTING this.channel.getListingAt!!!', {
      listingFound: !!(this.listing)
    });
    if (!this.listing) {
      this.error({
        category: errorCategories.player,
        error: {
          code: 'listingNotFound',
          description: `listing info not available for channelId ${channelId}`,
          fatal: true
        }
      });
      return;
    }
    await this.listing.loadDetails().catch(async (error) => {
      // Handle a listing load failure
      senderDebugger.debugErrorMessage('[APP][playChannelId][LISTING LoadDetails] ERROR WHILE LOADING Listing Details!!!! ',
        {
          listingError: error
        } );
      this.error(error);
      return;
    });
    if (!this.listing.creativeWork) {
      this.error(XVP.errorFormatted( {
        errorType: 'getTvListingDetail',
        options: {
          channelId: this.channel.channelId,
          stationId: this.channel.stationId,
          listingId: this.listing.listingId
        } })
      );
      return;
    }
    await this.listing.creativeWork.loadSeries();

    senderDebugger.sendDebugMessage('[APP][PlayChannelID] 6 SUCCESS LISTING CREATIVE WORK LOADED this.listing.creativeWork.loadSeries!!!', {
      creativeWork: this.listing.creativeWork
    });
    if (!this.listing.creativeWork) {
      this.error({
        category: errorCategories.player,
        error: {
          code: 'listingNotFound',
          description: `Creative work not available for channelId ${channelId}`,
          fatal: true
        }
      });
      return;
    }
    senderDebugger.sendDebugMessage('[APP][PlayChannelID] 7 ');
    if (!this.listing || this.playerController.error) {
      senderDebugger.debugErrorMessage('[APP][PlayChannelID] 7.1 NO LISTING FOUND !!', {
        listing: !!this.listing,
        playerControllerError: this.playerController.error
      });
      if (!this.playerController.error) {
        this.error({
          category: errorCategories.player,
          error: {
            code: 'listingNotFound',
            description: `listing creative work info not available for channelId ${channelId}`,
            fatal: true
          }
        });
      }
      return;
    }

    this.watchable = this.listing;
    if (
      this.watchable.isGeoFenced() ||
      (XVP.getFeature(featuresTypes.xvpHeartbeats) && this.watchable.channel && this.watchable.channel.hasGeofencedLocatorType())
    ) {
      try {
        this.listing = await this.checkGeoFencedStream(this.listing);
      } catch (error) {
        senderDebugger.debugErrorMessage('[APP][PlayChannelID] GEO FENCE FAILED!!', {
          geoFenceError: error
        });
        return;
      }
    }
    this.overlay.showMetadataFor(0);
    senderDebugger.sendDebugMessage('[APP][PlayChannelID] REACHED player.playAsset!!!!!!');
    player.playAsset({
      channelId: this.channel.channelId,
      watchable: this.listing
    });
    this.streamCheckRetry = 0;
    this.initialStreamCheckCompleted = false;
  }

  async playVideo({ media, customData }) {
    const { sessionId, programId, channelId, restart, resumePoint, unattendedAutoPlay, userPrivacyPreferences } = media.customData;

    senderDebugger.sendDebugMessage('[APP][playVideo] 0 start!!!', {
      mediaCustomData: media.customData,
      userFeatures: XVP.getFeatures()
    });

    const timestamp = Date.now();
    this.lastLoadTimestamp = timestamp;
    this.startingPlayback = true;
    this.wasPaused = false;
    player.userPrivacyPrefs = userPrivacyPreferences || this.defaultPrivacyPrefs;

    if (splunkLogger.vsid && !splunkLogger.isEndLogged(splunkLogger.vsid)) {
      splunkLogger.onVideoEnd();
    }

    const controllerChanged = this.controllingSession !== this.sessionDetails[sessionId];
    this.controllingSession = this.sessionDetails[sessionId];

    this.scrubber.position = 0;

    if (controllerChanged) {
      logger.log('Controller changed, purging data caches');
      ChannelModel.resetCache();
      ListingModel.resetCache();
      player.sendPlayerViewedEvent = true;
    }

    this.idle.state = 'playing';
    this.overlay.state = overlayStateMapping.initializing;
    this.overlay.showUpNext = false;
    // HDCP Check
    const hdcpVersion = await getHdcpVersion();
    if (hdcpVersion < hdcpMinVersion) {
      senderDebugger.debugErrorMessage(`[APP][playVideo][HDCP] Version level FAILED version:${hdcpVersion}`, {
        hdcpVersion: hdcpVersion,
        xboAccountId: Session.xboAccountId,
        senderDetails: Session.senderDetails
      });

      splunkLogger.onError({
        category: ['player.', errorCategories.outputRestricted],
        code: 'hdcpVersionFailed',
        subCode: 1,
        fatal: false,
        msg: `HDCP version check FAILED! HDCP: ${hdcpVersion}`,
        module: 'Video',
        senderDetails: Session.senderDetails,
        lookup: {
          category: errorCategories.outputRestricted,
          tvapp: '00236'
        }
      });
    }
    senderDebugger.sendDebugMessage('[APP][playVideo] 1 start!!!', {
      hdcpVersion: hdcpVersion
    });

    // Clear any messages
    this.message.innerHTML = '';
    this.underOverlayMessage.innerHTML = '';

    this.disableIdleTimeout();

    // Reset previous video state
    this.creativeWork = null;
    this.watchable = null;
    this.channel = null;
    this.nextEpisode = null;
    this.playNextRequest = null;
    this.listing = null;
    this.scrubber.adMode = false;
    this.scrubber.mediaLoading = true;
    this.adData = new AdData();
    this.scrubber.adData = this.adData;
    player.adData = this.adData;

    if (unattendedAutoPlay) {
      this.autoPlayCount++;
    } else {
      this.autoPlayCount = 0;
    }

    //  We call activate here to make sure the user's data (features etc) are used
    // Chromecast is able to connect to multiple senders and must only use the data from the play request

    try {
      if (this.controllingSession) {
        await this.controllingSession.activate();
      } else {
        throw new Error(`No session details found for ${sessionId}`); // TODO: Handle this better
      }
    } catch (error) {
      this.controllingSession = null;
      senderDebugger.debugErrorMessage('[APP][playVideo] ', {
        error: error,
        code: 'sessionNotFound',
        errorCategory: errorCategories.provision
      });
      this.error({
        category: errorCategories.provision,
        code: 'sessionNotFound',
        error
      });
      throw error;
    }

    try {
      if (media.contentType === 'application/vnd.comcast.watchable') {
        await this.playWatchable({
          watchable: await WatchableModel.fromUrl(media.contentId || media.contentUrl),
          timestamp,
          restart,
          resumePoint
        });
      } else if (programId) {
        await this.playProgramId({ programId, timestamp, restart, resumePoint });
      } else if (channelId) {
        await this.playChannelId({ channelId, timestamp });
      } else {
        senderDebugger.debugErrorMessage('[APP][playVideo] No programId or channelId provided. ' +
        'This should not happen and may indicate a sender defect.',
        {
          mediaFound: media,
          customData_sender: customData
        });
        throw new Error('No programId or channelId provided. This should not happen and may indicate a sender defect.');
      }
    } catch (error) {
      senderDebugger.debugErrorMessage('[APP][playVideo] Error within play video function',
        {
          errorFound: error

        });
      let errorObj = {
        category: errorCategories.player,
        error: error.xhr ? error.xhr.xtv : undefined
      };
      // TODO update this to separate xtvapi and xvp errors
      if (XVP.getFeature(featureTypes.xvpTVGrid) &&
       error.category === errorCategories.api ) {
        errorObj = error;
      }

      this.error(errorObj);
      return;
    }
  }

  async checkGeoFencedStream(watchable) {
    const servicePostalCode = this.controllingSession.session.tokenSummary.servicePostalCode || '';
    return await (XVP.getFeature(featuresTypes.xvpHeartbeats) ? watchable.channel.canStreamTve(servicePostalCode) : watchable.canStream())
      .then(() => watchable)
      .catch(async (error) => {
        const geoFenceErrors = ['21', '22', '23', '24'];
        const xvpGeoFenceErrors = ['403-21', '403-22', '403-23', '403-24'];
        let response;
        let subCode;
        let isGeofencedError;

        if (watchable.isLinear()) {
          const localChannel = await watchable.channel.getLocalChannelByCompanyId();

          if (localChannel) {
            /**
             * The local channels doessn't have any other channel data available
             * as it doesn'r have access to API cache.
             * Hence the original channel ID is saved as 'accountChannelId' and it should be used
             * in case we need to get more channel details.
             * Example: listing details
             */
            Object.assign(watchable.channel, localChannel,
              { accountChannelId: watchable.channel.channelId });
            this.playerController.channelId = watchable.channel.channelId;
            return watchable;
          }
        }

        if (XVP.getFeature(featureTypes.xvpHeartbeats)) {
          response = (error.response && await error.response.json()) || {};
          subCode = response.statusSubCode || '';
          isGeofencedError = xvpGeoFenceErrors.includes(subCode);
        } else {
          subCode = error.xhr.xtv && error.xhr.xtv.subCode;
          isGeofencedError = geoFenceErrors.includes(subCode);
        }


        if (isGeofencedError) {
          this.error({
            category: errorCategories.geofenceError,
            code: '403',
            subCode: subCode,
            description: 'Channel is not castable'
          });
          return false;
        }
        return watchable;
      });
  }

  /**
   * Display error screen and broadcast an ERROR message to Senders
   *
   * At a minimum, this must be called with a value for `category`. If `error`
   * is provided, it will be used to determine `code` and `subCode` values if
   * they aren't provided.
   *
   * A tvapp error code will be looked up from {@link constants.errors}
   *
   * @param {object} options
   * @param {string|string[]} options.category - Error category/categories
   * @param {object} options.error - XTV error object from an XTV API response
   * @param {string} options.code - Major error code
   * @param {string} options.subCode - Minor error code
   * @param {string} options.description - Error description
   */
  error({ category, error, code, subCode, description }) {
    this.startingPlayback = false;
    const modifiedError = Object.assign({}, error);
    if (modifiedError.watchable) delete modifiedError.watchable;

    senderDebugger.debugErrorMessage('[APP][ERROR] FUNC - 1 - modified error attempt ', {
      modifiedErrorAttempt: modifiedError,
      code: code,
      subCode: subCode,
      description: description || 'No Description found',
      category: category
    });

    // Make sure category is an array
    category = (category instanceof Array) ? category : [category];

    if (error) {
      category = [...category, error.endpoint];
      code = code || error.code;
      subCode = subCode || error.subCode;
      description = description || error.msg || error.message;
    }

    const errorDetails = lookupError([...category, code, subCode]);
    const errorContent = {
      category: category[0], // Only include top-level category in ERROR message
      code,
      subCode,
      tvapp: errorDetails.tvapp,
      description: errorDetails.description || description
    };
    const screen = category[0] === errorCategories.provision ?
      'Authentication Error' :
      'Playback Error';

    // It's unlikely we'll ever get an error without a playerController, but it
    // is theoretically possible (this also helps debugging since a SIMULATE_ERROR
    // message can be sent without having to start playback)
    if (this.playerController) {
      this.playerController.error = errorContent;
    }

    castMessenger.broadcastMessage(messageTypes.error, errorContent);

    this.overlay.state = 'idle';
    this.overlay.showMetadata = false;
    this.overlay.showScrubber = false;
    this.idle.state = 'failed';
    this.disableLinearTimeout();
    this.clearLinearBoundaryUpdate();

    splunkLogger.onError({
      ...(error ? error : errorContent),
      lookup: errorDetails
    });

    siftTracker.tagEvent('screen-viewed', { screen: screen });

    player.stopPlayback();
    this.enableIdleTimeout(defaultIdleTime);
    this.clearErrorScreen();
    const errorMessage = document.createElement('cast-error-message');
    errorMessage.errorDetails = errorDetails;
    this.message.appendChild(errorMessage);
    senderDebugger.debugErrorMessage('[APP] ERROR PARSED:', {
      errorMessage: errorMessage,
      errorContent: errorContent,
      errorDetails: errorDetails,
      screen: screen
    });
  }

  /**
   * Enable the idle timeout
   *
   * Starts a timeout which will stop the Receiver app after a specified period
   * of inactivity.
   *
   * @param {number} idleTimeout - How long to wait until exiting (in ms)
   */
  enableIdleTimeout(idleTimeout) {
    if (this.idleTimer) {
      this.disableIdleTimeout();
    }

    logger.log('Setting idle timeout for %i minutes  currently in [ ' + this.playState +' ] state ', idleTimeout / 60e3 );
    this.idleTimer = setTimeout(() => {
      logger.log('Idle timeout expired. Closing receiver app.  state:[ '+ this.playState +' ]');
      castMessenger.disconnect();
    }, idleTimeout);
  }

  /**
   * Disable the idle timeout
   */
  disableIdleTimeout() {
    if (!this.idleTimer) {
      return;
    }

    logger.log('Disabling idle timeout.  state:[ '+ this.playState +' ]');
    this.idleTimer = clearTimeout(this.idleTimer);
  }

  enableLinearTimeout() {
    if (this.linearTimer) {
      return;
    }

    this.linearTimer = setTimeout(() => {
      this.showPrompt(promptTypes.stillWatching);
    }, this.linearIdleTime);
  }

  disableLinearTimeout() {
    if (!this.linearTimer) {
      return;
    }

    this.linearTimer = clearTimeout(this.linearTimer);
  }

  resetLinearTimeout() {
    if (!this.linearTimer) {
      return;
    }

    this.disableLinearTimeout();
    this.enableLinearTimeout();
  }

  clearErrorScreen() {
    if (document.getElementsByTagName('cast-error-message').length >= 1) {
      this.message.removeChild(document.getElementsByTagName('cast-error-message')[0]);
    }
  }

  showPrompt({ header, message, pausePlayback, promptType, idleTimeout, details, siftTag }) {
    const active = !!promptType;

    this.playerController.prompt = { active, promptType, details };
    castMessenger.broadcastMessage(messageTypes.showPrompt, this.playerController.prompt);

    const existingPromptMessage = this.message.querySelector('[data-is-prompt]');
    if (existingPromptMessage) {
      this.message.removeChild(existingPromptMessage);
    }

    this.overlay.showUpNext = (promptType === 'up-next');

    if (siftTag) {
      siftTracker.tagEvent('screen-viewed', { screen: siftTag });
    }

    if (active) {
      if (header || message) {
        const promptMessage = document.createElement('cast-message');
        promptMessage.header = header;
        promptMessage.message = message;
        promptMessage.setAttribute('data-is-prompt', true);
        this.message.appendChild(promptMessage);
      }

      if (pausePlayback) {
        castMessenger.playerManager.pause();
      }
      senderDebugger.debugPromptMessage('Show Prompt', {
        header: header,
        message: message,
        pausePlayback: pausePlayback,
        promptType: promptType,
        idleTimeout: idleTimeout,
        showPromptData: details,
        siftTag: siftTag
      });
    }

    if (idleTimeout) {
      setTimeout(() => {
        logger.log(`Delayed setting of idle timeout for ${ promptType } prompt`);
        this.enableIdleTimeout(idleTimeout);
      });
    }
  }
}

export default new App();
export { App }; // For unit testing
