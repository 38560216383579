
/**
 * @module LinearAPI
 */
import Logger from 'lib/logger';
import { ChannelsApi } from '@devplat/xvp-linear-client';
import config from 'config';
import { senderDebugger } from '../../debug/sender-receiver-debug';

const STG_BASE_PATH = 'https://linear-stg.exp.xvp.na-1.xcal.tv';
const PROD_BASE_PATH = 'https://linear.exp.xvp.na-1.xcal.tv';
const BASE_PATH = config.environment.API_ENV !== 'prod' ? STG_BASE_PATH : PROD_BASE_PATH;

const QUERY_PARAM_STRINGIFY_METHOD = (query) => {
  return Object.keys(query).map((key) => key + '=' + query[key]).join('&');
};
const logger = new Logger('XVP-LinearAPI', { background: 'blue', color: 'white' });

class LinearAPI {
  _session = null;
  _ready = false;
  _channelsApi = null;
  environment = 'prod';

  get ready() {
    return this._ready;
  }

  get channelsApi() {
    if (!this._channelsApi || !this.ready) {
      logger.error('channelsApi: channelsApi is: ' + this._channelsApi +
    ' Linear Class Service ready is: ' + this.ready );
      return null;
    }
    return this._channelsApi;
  }

  init( params = {} ) {
    if (!params.accessTokenResponse) {
      logger.error('Linear API: did not provide accessTokenResponse');
      return;
    }
    // TODO error check on session config data
    this._session = params.session;
    this._accessTokenResponse = params.accessTokenResponse;
    this._ready = true;
    this.environment = config.env || config.environment.ENV;
    this._channelsApi = this._getChannelsApi();
  //  logger.log(' can I access getChannelsApi( here? ' + JSON.stringify(config));
  }

  async getEntitledTvGridChannels(options = {}) {
    senderDebugger.debugNetworkMessage('[XVP][API] getEntitledTvGridChannels using _channelsApi: options ', options);
    return await this._channelsApi && this._channelsApi.getEntitledTvGridChannelsRaw(options);
  }

  async getLocalTvGridChannels(options = {}) {
    try {
      const response = await this._channelsApi && this._channelsApi.getLocalTvGridChannels(options);
      return response;
    } catch (error) {
      logger.error(error);
      return {};
    }
  }

  _getChannelsApi( options = {} ) {
    if (!this._isApiReady()) {
      logger.warn('Linear API is not ready: some tokens might be missing!!');
      return;
    }

    return new ChannelsApi({
      basePath: BASE_PATH,
      fetchApi: window.fetch.bind(window),
      xsct: this._session.xsct,
      accessToken: this._accessTokenResponse,
      queryParamsStringify: QUERY_PARAM_STRINGIFY_METHOD,
      middleware: []
    });
  }

  _isApiReady() {
    return this._accessTokenResponse && this.ready;
  }
}

export default new LinearAPI();
