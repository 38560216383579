/**
 * @module XVPDEVPLAT
 */

import { dispatches } from 'lib/event-dispatcher';
import { getXVPEndpoint } from './xvp-services';
import { senderDebugger } from 'lib/debug/sender-receiver-debug';
import Logger from 'lib/logger';

import DiscoAPI from './xvp-bff-client/disco-service';
import LinearAPI from './xvp-bff-client/linear-service';
import PlaybackAPI from './xvp-bff-client/playback-service';
import RightsAPI from './xvp-bff-client/rights-service';
import SessionAPI from './xvp-bff-client/session-service';

/**
 * Singleton module for working with generated XVP clients. This object should be fed a
 * valid session (xsct, sat, deviceId, xboAccountId) before interaction with XVP.
 */
const CLIENT_ID = 'stream-chromecast';
const logger = new Logger('XVPDEVPLAT', { background: 'blue', color: 'white' });
@dispatches('XVPDEVPLAT')

class XVPDEVPLAT {
  LinearAPI = LinearAPI;
  SessionAPI = SessionAPI;
  DiscoAPI = DiscoAPI;
  PlaybackAPI = PlaybackAPI;
  RightsAPI = RightsAPI;

  _session = {
    partnerId: '',
    deviceId: '',
    clientId: '',
    accountId: '',
    xboAccountId: '',
    xsct: ''
  };

  accessTokenResponse() {
    return this._session.serviceAccessToken;
  }

  async initServiceAPI(endPoint) {
    const targetEndpoint = endPoint;
    const xvpServiceData = getXVPEndpoint(targetEndpoint);
    const servicePoint = this[xvpServiceData.endPointData.xvpServiceName];

    if (!servicePoint.ready) {
      servicePoint.init(Object.assign({}, { session: this._session, accessTokenResponse: this.accessTokenResponse.bind(this) }));

      this[xvpServiceData.endPointData.xvpServiceName] = this[xvpServiceData.endPointData.xvpServiceName] || null;
      if (!this[xvpServiceData.xvpApi]) {
        senderDebugger.sendDebugMessage('[XVPDEVPLAT][initServiceAPI]', {
          targetEndpointRequested: targetEndpoint,
          xvpServiceDataSent: xvpServiceData,
          servicePointRequested: servicePoint
        });

        servicePoint.init(Object.assign({}, { session: this._session, accessTokenResponse: this.accessTokenResponse.bind(this) }));
        this[xvpServiceData.xvpApi] = servicePoint[xvpServiceData.xvpApi];
      }
    }
  }

  async send(session, params) {
    this._session = session;
    const endPoint = params.endPoint;
    const xvpServiceData = getXVPEndpoint(endPoint);
    const xvpService = this[xvpServiceData.endPointData.xvpServiceName];
    const tokenSummary = session.tokenSummary || {};
    let logResponse = {};
    let rawXVPResponse = undefined;

    senderDebugger.debugNetworkMessage(`[XVPDEVPLAT][API] Endpoint: ${endPoint} PRE-SEND CALLED:`, {
      endPointRequested: endPoint,
      paramsSent: params,
      xvpService: xvpServiceData
    });

    if (!xvpService.ready || !this[xvpServiceData.xvpApi]) {
      this.initServiceAPI(endPoint);
    }
    const endPointData = xvpServiceData.endPointData.endPoints;
    let xvpMethodData = endPointData.find((pointResource) => {
      return pointResource.xtvEndpoint === endPoint;
    });
    if (!xvpMethodData) {
      xvpMethodData = endPointData.find((pointResource) => {
        return pointResource.xvpApiCall === endPoint;
      });
    }
    const resource = endPoint && xvpService;

    if (!resource || !this.accessTokenResponse()) {
      logger.log('XVPDEVPLAT pre-send authTokenfailed, missing session/sat token');
      return;
    }

    const requiredParams = {
      clientId: CLIENT_ID,
      partnerId: tokenSummary.partnerId,
      accountId: tokenSummary.xboAccountId,
      deviceId: tokenSummary.deviceId
    };
    const opts = Object.assign({}, requiredParams, params);

    senderDebugger.debugNetworkMessage(`[XVPDEVPLAT][API] Endpoint:${endPoint} SEND CALLED:`, {
      endPointRequested: endPoint,
      paramsSent: params,
      xvpService: xvpServiceData,
      xvpServiceName: xvpServiceData.endPointData.xvpServiceName,
      xvpApiServiceCall: xvpMethodData.xvpApiCall
    });

    return await this[xvpServiceData.endPointData.xvpServiceName][xvpMethodData.xvpApiCall]( // request override params here...
      opts
    ).then(async (response) => {
      senderDebugger.debugNetworkMessage('ATTEMPT JSON XVPDEVPLAT RESPONSE RAW:', response);
      // need to handle end point if not used with 'raw' xvp api calls
      if (response && response.raw) {
        rawXVPResponse = response.raw.clone();

        if (response.raw.clone) {
          logResponse = response.raw.clone();
        }
        if (logResponse.json) {
          return await logResponse.json();
        }
      }

      return response;
    }).then(async (response) => {
      if (rawXVPResponse) {
        return rawXVPResponse;
      }
      return response;
    });
  }
}

export default new XVPDEVPLAT();
export { XVPDEVPLAT };
